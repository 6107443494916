<template>
    <div id="photography_show">
        <inc_header></inc_header>

        <div class="container">
            <div class="banner"><el-image :src="banner.srcPc" fit="cover"></el-image></div>

            <div class="show">
                <h2 v-if="photography.category===1">模特 <span>MichelleDance models</span></h2>
                <h2 v-else>摄影师 <span>MichelleDance photographers</span></h2>
                <div class="left">
                    <el-image :src="photography.thumbnail" fit="cover"></el-image>
                    <span class="name">{{ photography.name }}</span>
                    <span class="summary">{{ photography.summary }}</span>
                </div>
                <div class="right" v-html="photography.intro"></div>
            </div>
        </div>

        <inc_footer></inc_footer>
    </div>
</template>

<style scoped>
    .banner { height:480px; }
    .banner .el-image { width:100%; height:100%; }

    .show { margin:60px 0; color:#666; }
    .show:after { height:0; display:block; content:''; clear:both; visibility:hidden; }
    .show h2 { margin:0 0 40px 0; font-size:24px; font-weight:400; color:#000; }
    .show h2 span { color:#a00e15; }
    .show .left { width:310px; padding-right:40px; float:left; }
    .show .left .el-image { width:270px; height:360px; margin-bottom:10px; }
    .show .left span.name { font-size:18px; color:#a00e15; display:block; }
    .show .left span.summary { font-size:16px; color:#000; }
    .show .right { width:890px; float:left; }
    .show .right p:first-child { margin-top:0; }
    .show .right img { max-width:100%; }
</style>

<script>
    import inc_header from "../components/inc_header";
    import inc_footer from "../components/inc_footer";

    export default {
        name: "photography_show",
        components: {
            inc_header,
            inc_footer
        },
        data() {
            return {
                banner: {},  // Banner
                photography: {}
            }
        },
        created() {
            let that = this;

            // 获取“Banner”
            this.$axios.post(this.$global.config.serverApi+'/banner.php',{ action:"getByCategory", category:"photography" }).then( function(response) {
                if (response.data.complete === true) {
                    that.banner = response.data.banner;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });

            // 获取“芭蕾摄影”
            this.$axios.post(this.$global.config.serverApi+'/photography.php',{ action:"get", photographyId:this.$route.params.id }).then( function(response) {
                if (response.data.complete === true) {
                    that.photography = response.data.photography;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });
        }
    }
</script>
